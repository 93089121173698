<template>
  <div class="teambition">
    <h3>Teambition<br/>阿里巴巴旗下团队协作工具</h3>
    <p>一套聪明好用的日常工具，包含项目、待办、网盘、文档、日历等丰富应用，帮助你把想法变成现实，使用起来爱不释手。</p>
    <section class="remark-box">
      <transition name="slide-fade" mode="out-in">
        <div v-if="item == 1" key="1">
          <h2>项目协作</h2>
          <p>打造通⽤的、跨职能、轻量级的项⽬管理中台，围绕「项⽬」构建协同作战能⼒，向上⽀撑业务策略，向下落实团队执⾏，有⼒推动实现业务⽬标。</p>
        </div>
        <div v-else-if="item == 2" key="2">
          <h2>敏捷产品开发</h2>
          <p>经过阿⾥集团多年实践，帮助企业快速且⾼质量地交付产品，达成更佳业务效果。⽅案可形成规模化的落地机制，保障实施的可持续性。</p>
        </div>
        <div v-else-if="item == 3" key="3">
          <h2>知识工厂</h2>
          <p>从0到1构建企业知识库，通过系统化知识管理，让知识资产的「⽣产-沉淀-使⽤」形成通路，有价值的信息更透明和可被获取，更好满⾜业务需求。</p>
        </div>
      </transition>
      <em :style="{ left: 'calc( ( 33.33% * ' + item + ' ) - 10px - 16.66% )' }"></em>
    </section>
    <ul>
      <li :class="{ 'on': item == 1 }" @click="item = 1">
        <div class="li_icon li_1" />项目协作
      </li>
      <li :class="{ 'on': item == 2 }" @click="item = 2">
        <div class="li_icon li_2" />敏捷产品开发
      </li>
      <li :class="{ 'on': item == 3 }" @click="item = 3">
        <div class="li_icon li_3" />知识工厂
      </li>
    </ul>
    <div class="btn-list">
      <a :href="onLine" target="_blank" v-if="$route.path != '/cloud/teambition'">立即咨询</a>
      <a :href="onLine" target="_blank" :style="{ flex: 1, width: 'auto', margin: 'auto', color: '#fff' }"
        v-else>立即咨询，了解更多</a>
      <button @click="$router.push('/cloud/teambition')" v-if="$route.path != '/cloud/teambition'">了解详情</button>
    </div>
  </div>
</template>
<script>
import { onLine } from "@/data";
export default {
  data() {
    return {
      item: 1,
      onLine
    }
  },
}
</script>
<style lang="less" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity .3s
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0
}

.teambition {
  padding: 50px 25px;
  background-color: white;

  >h3 {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #383838;
  }

  >p {
    font-size: 15px;
    color: #383838;
    line-height: 2;
    font-weight: 300;
    margin-top: 25px;
    text-align: justify;
  }

  >section {
    margin-top: 50px;
    background: #3D4B7E;
    padding: 20px;
    color: #fff;
    position: relative;

    h3 {
      font-size: 17px;
      font-weight: bold;
    }

    p {
      margin-top: 15px;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.5;
      text-align: justify;
    }

    >em {
      position: absolute;
      bottom: -11px;
      left: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid #3D4B7E;
      transition: .2s left;
    }
  }

  >ul {
    display: flex;
    margin-top: 30px;

    >li {
      width: 33.33%;
      text-align: center;
      font-size: 15px;
      color: #3D4B7E;

      .li_icon {
        width: 57px;
        height: 57px;
        margin: auto auto 10px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.li_1 {
          background-image: url(../../assets/index/tb_1.png);
        }

        &.li_2 {
          background-image: url(../../assets/index/tb_2.png);
        }

        &.li_3 {
          background-image: url(../../assets/index/tb_3.png);
        }
      }

      &.on {
        .li_icon {
          &.li_1 {
            background-image: url(../../assets/index/tb_1_on.png);
          }

          &.li_2 {
            background-image: url(../../assets/index/tb_2_on.png);
          }

          &.li_3 {
            background-image: url(../../assets/index/tb_3_on.png);
          }
        }
      }
    }
  }

  .btn-list {
    display: flex;
    margin-top: 50px;

    >button,>a {
      text-align: center;
      display: inline-block;
      width: 50%;
      margin: 0 10px;
      border: 0 none;
      background: none;
      appearance: none;
      border-radius: 3px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        background-color: #E80B10;
        color: #fff;
      }

      &:last-child {
        border: 1px solid #E80B10;
        color: #E80B10;
      }
    }
  }
}
</style>

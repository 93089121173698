<template>
  <div class="tb-banner">
    <h3>teambition</h3>
    <p>一套聪明好用的日常工具，包含<br/>项目、待办、网盘、文档、日历等丰富应用，<br/>帮助你把想法变成现实，<br/>使用起来爱不释手</p>
    <a :href="onLine" target="_blank">立即咨询</a>
  </div>
  <Teambition/>
  <Harvest/>
  <Solution/>
  <Cases/>
</template>
<script>
import { onLine } from "@/data.ts";
import Teambition from '@/components/home/Teambition.vue';
import Harvest from '@/components/teambition/Harvest.vue';
import Cases from '@/components/teambition/Cases.vue';
import Solution from '@/components/teambition/Solution.vue';
export default {
  components:{
    Teambition,
    Harvest,
    Cases,
    Solution
  },
  data() {
    return { onLine }
  }
}
</script>
<style lang="less" scoped>
.tb-banner{
  padding: 65px 25px;
  background: url(/static/img/teambition-banner.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    font-size: 20px;
    font-weight: bold;
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8;
    margin-top: 10px;
  }
  >a{
    line-height: 40px;
    text-align: center;
    display: inline-block;
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>